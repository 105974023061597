<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="viewPurch" backdrop width="90%" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="اضافة سيارة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{bobtitle}}</span>
                        </div>
                        <div @click="hide" id="viewBillHide" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="px-3 py-2" style="direction:rtl">
                        <div class="myBtn backBlack" :style="`direction:${lang.dir};`">
                            <b-button type="button" class="btnRow c_darkblue" id="addPurch" variant="success" @click='deleteAll()' style="width:120px;" v-if="billifno.jvnum == 0 || billifno.jvnum == ''">{{ lang.delete_invoice }}</b-button>
                            <b-button type="button" class="btnRow backRed" id="addPurch" variant="success" @click='deleteSelected()' style="width:120px;" v-if="billifno.jvnum == 0 || billifno.jvnum == ''">{{ lang.delete_selected }}</b-button>
                            <b-button type="button" class="btnRow c_darkslateblue" id="addPurch" variant="success" @click='returnAll()' style="width:120px;display:none">{{lang.return_invoice}}</b-button>
                            <b-button type="button" class="btnRow c_darkorange" id="addPurch" variant="success" @click='returnSelected()' style="width:120px;display:none">{{lang.return_selected}}</b-button>
                            <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:blue !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="showAttached()" v-b-toggle.add_gallery>{{lang.attachement}}</b-button> 
                            <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="deletePost(billifno.id)" v-if="billifno.jvnum != 0 && billifno.jvnum != ''">{{lang.cancel_post}}</b-button> 
                            <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="rePost(billifno.id)" v-else>{{lang.repost}}</b-button> 
                        </div>
                        <div class="container">
                            <div><h6 class="backBlack text-center p-2">{{view_title}}</h6></div>
                            <b-table-simple :style="`direction:`+lang.dir">
                                <thead>
                                    <tr>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.invoiceno}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.bill_number }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.invoice_date}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ String(billifno.bill_date).substring(0,10) }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.vendor}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.vendor }}</td>
                                    </tr>
                                    <tr>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%"> {{lang.vendor_vatids}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.vat_number }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.vendor_mobile}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.mobile }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.bill_type}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.invtype }}</td>
                                    </tr>
                                    <tr>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%"> {{lang.payment_method}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.paytype }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.vendor_address}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.vendor_address }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.bill_type}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ cashajl }}</td>
                                    </tr>
                                </thead>
                            </b-table-simple>

                        </div>
                        <div class="container">
                            <div><h6>{{lang.invoice_details}}</h6></div>
                            <v-simple-table striped hover style="width:100%;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th style="text-align:center;" class="backBlack">{{lang.item_code}}</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.item_name}}</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.qtty}}</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.item_price}}</th>
                                            <th style="text-align:center;" class="backRed">{{lang.total}}</th>
                                            <th style="text-align:center;" class="backGreen">{{lang.vat}} 15%</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.ftotal}}</th>
                                            <th style="text-align:center;width:50px;" class="backBlue">{{ lang.action }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in billifno.rows" :key="index">
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" @change="getProduct(index)" v-model="billifno.rows[index].item_number" /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="billifno.rows[index].item_name" readonly /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="billifno.rows[index].qty" /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="billifno.rows[index].item_price" /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="billifno.rows[index].total" readonly /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="billifno.rows[index].vat" readonly /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="billifno.rows[index].ftotal" readonly /></td>
                                            <td style="text-align:center;"><b-form-checkbox @change="markMe(index)"   v-if="billifno.jvnum == 0 || billifno.jvnum == ''" /></td>
                                            <!-- <td style="text-align:center;"></td> -->
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr   v-if="billifno.jvnum == 0 || billifno.jvnum == ''">
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" @change="getProduct(-1)" v-model="newrow.item_number" /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="newrow.item_name" /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" @change="updateTotal()" v-model="newrow.qty" /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" @change="updateTotal()" v-model="newrow.item_price" /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="newrow.total" readonly /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="newrow.vat" readonly /></td>
                                            <td style="text-align:center;"><b-form-input class="inborder" type="text" v-model="newrow.ftotal" readonly /></td>
                                            <td style="text-align:center;cursor:pointer;" @click="addNewRow()" class="backBlue">{{ lang.add }}</td>
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>
                                            <th :style="`text-align:`+lang.lalgin" class="backRed">{{lang.prices_exclusive_vat}}</th>
                                            <th :style="`background:lightgreen !important;text-align:`+lang.algin">{{totals}}</th>
                                            <th class="backEmpty"></th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>
                                            <th :style="`text-align:`+lang.lalgin" class="backBlack">{{lang.discount}}</th>
                                            <th :style="`text-align:`+lang.algin">{{billifno.discount}}</th>
                                            <th class="backEmpty"></th>    
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>                          
                                            <th :style="`text-align:`+lang.lalgin" class="backGreen">{{lang.vat}} 15%</th>
                                            <th :style="`background:lightgreen !important;text-align:`+lang.algin">{{vats}}</th>
                                            <th class="backEmpty"></th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>                                                       
                                            <th :style="`text-align:`+lang.lalgin" class="backBlack">{{lang.prices_include_vat}}</th>
                                            <th :style="`text-align:`+lang.algin">{{ftotals}}</th>
                                            <th class="backEmpty"></th>
                                        </tr>


                                        
                                    </tfoot>
                                </template>
                            </v-simple-table>
                            <div style="display:none;"><h6>{{lang.payments}}</h6></div>
                            <v-simple-table striped hover style="width:100%;display:none;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th style="text-align:center;">{{lang.date}}</th>
                                            <th style="text-align:center;">{{lang.amounttotal}}</th>
                                            <th style="text-align:center;">{{lang.payment_method}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in payrows" :key="index">
                                            <td>{{item.receipt_date}}</td>
                                            <td>{{item.ftotal}}</td>
                                            <td>{{item.paytype}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                    <purchaseCardGallery ref="viewCardGall" />
                </template>
                <template #footer="" class="shadow">
                    
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>


<script>
import axios from 'axios'
import purchaseCardGallery from './purchaseCardGallery.vue';
import {SnotifyPosition} from 'vue-snotify';
export default{
  components: { purchaseCardGallery },
    name: 'ViewPurchase',
    data() {
        return {
            totals: 0,
            vats: 0,
            ftotals: 0,
            billid: 0,
            billifno: {

            },
            payrows: [],
            selected: [],
            returned: [],
            newrow: {
                item_number: '',
                item_name: '',
                qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
            }

        }
    },
    created() {
        // this.getBill();
        this.getPays();
    },
    methods: {
        deletePost(id){
            let message = this.$parent.lang.post_purchase_note;
            this.$snotify.error(message, this.$parent.lang.alert, {
                timeout: 1000000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: SnotifyPosition.centerCenter,
                buttons: [
                    {
                        text: this.$parent.lang.agree, 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                            this.deltePostOK(id)
                        } 
                    },
                    {
                        text: this.$parent.lang.cancel, 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            });
            return false;
        },
        deltePostOK(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","deletePurchasePost");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.getBill();
            });
        },
        rePost(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","rePostPurchase");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.getBill();
            });
        },
        showAttached(){
            this.$refs.viewCardGall.card.cardid = this.billid;
            this.$refs.viewCardGall.getImages();
            
        },
        updateTotal(){
            if(this.newrow.qty == '') this.newrow.qty = 1
            if(this.newrow.item_price == '') this.newrow.item_price = 0 
            let total = parseFloat(this.newrow.qty) * parseFloat(this.newrow.item_price)
            const vat = parseFloat(total) * parseFloat(0.15);
            const ftotal = parseFloat(total) + parseFloat(vat);
            this.newrow.total = this.$RoundNums(total,2);
            this.newrow.vat = this.$RoundNums(vat,2);
            this.newrow.ftotal = this.$RoundNums(ftotal,2);
        },
        addNewRow(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","addNewRowToPurchase");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[row]',JSON.stringify(this.newrow));
            post.append('data[billid]',this.billid);
            post.append('data[bill_date]',this.billifno.bill_date);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.resetNewRow();
                this.getBill();
            });
        },
        getProduct(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_number]',this.newrow.item_number);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].item_type == 1){
                        this.newrow.item_number = response.data.results.data[0].item_code;
                        this.newrow.item_name = response.data.results.data[0].item_namear;
                        this.newrow.item_price = response.data.results.data[0].default_price;
                        this.newrow.unit_id = response.data.results.data[0].main_unit;
                        this.newrow.unit = response.data.results.data[0].main_unit;
                        this.itemUnits = response.data.results.data[0].itemUnits;
                    }else{
                        this.newrow.item_number = '';
                        let message = this.$parent.lang.this_item_service_item;
                        this.$snotify.error(message, this.$parent.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                        return false;
                    }
                }
                this.calcKit();
            })
        },
        resetNewRow(){
            this.newrow = {
                item_number: '',
                item_name: '',
                qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
            }
        },
        deleteSelected(){
            if(this.selected.length == 0){
                alert('لم يتم تحديد اي صف');
                return false;
            }
            if(this.billifno.rows.length == 1){
                this.deleteAll();
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','deleteSelected');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',JSON.stringify(this.selected));
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.getBill();
                this.$parent.getInvoice();
                alert("تم حذف المحدد");
                document.getElementById('viewBillHide').click();
            });
        },
        returnSelected(){
            if(this.returned.length == 0){
                alert('لم يتم تحديد اي صف');
                return false;
            }
            
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','returnSelected');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            let dat = [];
            
            for(let i=0;i<this.returned.length;i++){
               
                dat.push(this.billifno.rows[this.returned[i]])
            }
            post.append('data[id]',JSON.stringify(dat));
            post.append('data[vattype]',this.billifno.vattype);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.getBill();
                this.$parent.getInvoice();
                alert("تم ارجاع المحدد");
                document.getElementById('viewBillHide').click();
            });
        },
        returnAll(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','retPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.billifno.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.$parent.getInvoice();
                alert("تم ارجاع الفاتورة");
                document.getElementById('viewBillHide').click();
            });
        },
        deleteAll(){
            
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','delPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.billifno.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.$parent.getInvoice();
                alert("تم حذف الفاتورة بنجاح");
                document.getElementById('viewBillHide').click();
            });
        },
        markMe(index){
            let added = true;
            for(let i=0;i<this.selected.length;i++){
                if(this.billifno.rows[index].id == this.selected[i]){
                    added = false;
                    this.selected.splice(i,1);
                }
            }
            if(added){
                this.selected.push(this.billifno.rows[index].id)
            }
            let rets = true;
            for(let i=0;i<this.returned.length;i++){
                if(index == this.returned[i]){
                    rets = false;
                    this.returned.splice(i,1);
                }
            }
            if(rets){
                this.returned.push(index)
            }
        },
        getPays(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getbillPayments');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',this.billid);
             axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((resp) => {
                const res = resp.data;
                // // console.log(res);
                this.payrows = res.results.data;
                
            })
        },
        getBill(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getBillinfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.billid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((resp) => {
                const res = resp.data;
                
                this.billifno = res.results.data;
                
            }).then(() => {
                if(this.billifno.invtype == 1) { this.billifno.invtype = this.lang.purchase_invoice;}
                else{this.billifno.invtype == this.lang.expenses}

                if(this.billifno.paytype == 1) { this.billifno.paytype = this.lang.cash_payment;}
                else{this.billifno.paytype == this.lang.bank_payment}

                this.billifno.bill_date = this.billifno.bill_date.toString().substr(0, 10);
                this.totals = this.billifno.total;
                this.vats = this.billifno.vat;
                this.ftotals = this.billifno.ftotal;
                // this.vats = 0;
                // this.ftotals = 0;
                // if(this.billifno.rows.length > 0){
                //     for(let i=0;i<this.billifno.rows.length;i++){
                //         this.totals = +this.totals + parseFloat(this.billifno.rows[i].total);
                //         this.vats = +this.vats + parseFloat(this.billifno.rows[i].vat);
                //         this.ftotals = +this.ftotals + parseFloat(this.billifno.rows[i].ftotal);
                //     }
                // }
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        cashajl: function(){
            if(this.billifno.cashajl == 1){
                if(this.lang.langname == "ar"){
                    return "فاتورة نقدية";
                }else{
                    return "Cash Invoice";
                }
            }
            if(this.lang.langname == "ar"){
                    return "فاتورة آجلة";
                }else{
                    return "Credit Invoice";
                }
        },
        bobtitle: function(){
            if(this.billifno.expeneses_type == 0){
                return this.lang.view_purchase_bill.substring(4)
            }
            return this.lang.expenses
        },
        view_title: function(){
            if(this.billifno.expeneses_type == 0){
                return this.lang.view_purchase_bill
            }
            return this.lang.view_expenses
        },
        items: function(){
            return {
                    text: this.lang.view_purchase_bill,
                    disabled: true,
                    to: '/view-purchase',
                }
        }
    },
}
</script>
<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>